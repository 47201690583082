import React from "react";
import { PageHeaderUi } from "../../../../ui/PageHeaderUi";
import { ButtonUi } from "../../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../../types/typesConstants";
import { Col, Row } from "antd";
import { ProgramacionTejidoTable } from "./ProgramacionTejidoTable";
import { useDispatch, useSelector } from "react-redux";
import { showFormProgramacionTejido } from "../../../../../actions/produccion";
import { ProgramacionTejidoScreen } from "./ProgramacionTejidoScreen";
import { ProgramacionTejidoSearchTable } from "./ProgramacionTejidoSearchTable";

const ProgramacionTejidoListScreen = () => {
  const dispatch = useDispatch();
  const { programacionesTejido, loading } = useSelector(
    (state) => state.produccion
  );
  const handleNuevo = () => {
    dispatch(showFormProgramacionTejido());
  };

  return (
    <PageHeaderUi
      title="Programación de Tejido"
      subTitle="Listado"
      extra={[
        <ButtonUi
          key="nuevo"
          typeUi={TipoButtonConstants.Nuevo}
          onClick={handleNuevo}
        />,
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            <ProgramacionTejidoSearchTable />
          </Col>
        </Row>
        <Row>
          <Col sm={32} md={32} xl={24}>
            <ProgramacionTejidoTable
              data={programacionesTejido}
              loading={loading}
            />
          </Col>
        </Row>
      </div>
    </PageHeaderUi>
  );
};

export const ProgramacionTejidoList = () => {
  const { visibleFormProgramacionTejido } = useSelector(
    (state) => state.produccion
  );
  if (visibleFormProgramacionTejido) {
    return <ProgramacionTejidoScreen />;
  } else {
    return <ProgramacionTejidoListScreen />;
  }
};
export default ProgramacionTejidoList;
