import React, { useState } from "react";
import { search_proveedores_by_name_or_code } from "../../../../actions/proveedor";
import { SearchSelectUI } from "../../../ui/SearchSelectUI";

export const ProveedorSearchSelect = ({ searchText, ...restProps }) => {
  const [proveedores, setProveedores] = useState([]);
  const [valueSearch, setValueSearch] = useState(searchText);
  const OnSearch = async (value) => {
    const data = await search_proveedores_by_name_or_code(value);
    setProveedores(data);
    setValueSearch(value);
  };
  return (
    <SearchSelectUI
      handleSearch={OnSearch}
      data={proveedores}
      value={restProps?.value}
      searchValue={valueSearch}
      {...restProps}
    />
  );
};
