import { Form, Table } from "antd";
import React, { useEffect, useState } from "react";

const RowTotalesAfectacionIGV = ({ totales }) => (
  <>
    {totales?.map((e, index) => (
      <Table.Summary.Row key={index}>
        <Table.Summary.Cell index={0} colSpan={6} align="right">
          <b>{e?.label}</b>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6} align="right">
          {e.total}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    ))}
  </>
);

export const ComprobanteCompraTableSummary = () => {
  const form = Form.useFormInstance();
  const detallescomprobantecompra = Form.useWatch(
    "detallescomprobantecompra",
    form
  );
  const [sumTotalesAfectacionIGV, setSumTotalesAfectacionIGV] = useState([]);
  const [sumTotales, setSumTotales] = useState(null);
  const fn_CalcularTotales = (data) => {
    let montototalgravadas = 0;
    let montototalinafectas = 0;
    let montototalexoneradas = 0;
    let montototalgratuitas = 0;
    let montototalexportaciones = 0;
    let montoigvtotal = 0;
    let montototal = 0;

    data?.forEach((e) => {
      if (e?.grupotipoafectacionigv === "OPE. GRAVADA") {
        montototalgravadas += e.valorcomprabruto;
      } else if (e?.grupotipoafectacionigv === "OPE. EXONERADA") {
        montototalexoneradas += e.valorcomprabruto;
      } else if (e?.grupotipoafectacionigv === "OPE. INAFECTA") {
        montototalinafectas += e.valorcomprabruto;
      } else if (e?.grupotipoafectacionigv === "EXPORTACION") {
        montototalexportaciones += e.valorcomprabruto;
      } else if (e?.grupotipoafectacionigv === "OPE. GRATUITA") {
        montototalgratuitas += e.valorcomprabruto;
      }
      montoigvtotal += parseFloat(e.valorigv);
      montototal += parseFloat(e.valorcompra);
    });
    montototalgravadas = parseFloat(parseFloat(montototalgravadas).toFixed(4));
    montototalexoneradas = parseFloat(
      parseFloat(montototalexoneradas).toFixed(4)
    );
    montototalinafectas = parseFloat(
      parseFloat(montototalinafectas).toFixed(4)
    );
    montoigvtotal = parseFloat(parseFloat(montoigvtotal).toFixed(4));
    let totales = [];
    if (montototalgravadas > 0) {
      totales = [
        ...totales,
        {
          label: "Ope. Gravada",
          total: parseFloat(montototalgravadas).toFixed(2),
        },
      ];
    }
    if (montototalexoneradas > 0) {
      totales = [
        ...totales,
        {
          label: "Ope. Exonerada",
          total: parseFloat(montototalexoneradas).toFixed(2),
        },
      ];
    }
    if (montototalinafectas > 0) {
      totales = [
        ...totales,
        {
          label: "Ope. Inafecta",
          total: parseFloat(montototalinafectas).toFixed(2),
        },
      ];
    }

    setSumTotalesAfectacionIGV(totales);
    setSumTotales({ montoigvtotal, montototal });
    form.setFieldsValue({
      montototalgravadas,
      montototalexoneradas,
      montototalinafectas,
      montoigvtotal,
    });
  };
  useEffect(() => {
    fn_CalcularTotales(detallescomprobantecompra);
  }, [detallescomprobantecompra]);

  return (
    <>
      <RowTotalesAfectacionIGV totales={sumTotalesAfectacionIGV} />
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={6} align="right">
          <b>IGV</b>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6} align="right">
          {parseFloat(sumTotales?.montoigvtotal).toFixed(4)}
        </Table.Summary.Cell>
      </Table.Summary.Row>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={6} align="right">
          <b>Importe Total</b>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6} align="right">
          {parseFloat(sumTotales?.montototal).toFixed(4)}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );
};
