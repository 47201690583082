import React from "react";
import { TableUi } from "../../../../ui/TableUi";
import { useDispatch } from "react-redux";
import {
  consolidadoTelaSetActive,
  get_pdf_consolidado_tela,
  get_pdf_programacion_tintoreria,
  programacionTintoreriaSetActive,
  programacionTintoreriaStartDelete,
  showFormConsolidadoTela,
  showFormProgramacionTintoreria,
} from "../../../../../actions/produccion";
import { showDeleteConfirm } from "../../../../../helpers/confirmModal";
import {
  DeleteOutlined,
  DiffOutlined,
  EditOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { open_pdf_file } from "../../../../../helpers/utils";
import { EstadoProgramacionTag } from "../../reusable/EstadoProgramacionTag";
import { DropdownButtonUi } from "../../../../ui/DropdownButtonUi";
import { Space } from "antd";
let itemsActions = [
  {
    key: "editar",
    label: "Editar",
    icon: <EditOutlined />,
  },
  {
    key: "eliminar",
    label: "Eliminar",
    icon: <DeleteOutlined />,
  },
  {
    key: "consolidar",
    label: "Consolidar tela",
    icon: <DiffOutlined />,
  },
];
const reportesActions = [
  {
    key: "pdf_tintoreria",
    label: "P.Tintoreria",
    icon: <FilePdfOutlined style={{ color: "#FF0000" }} />,
  },
  {
    key: "pdf_consolidado",
    label: "Consolidado Tela",
    icon: <FilePdfOutlined style={{ color: "#FF0000" }} />,
  },
];
const handleMenuClick = (e, record, acciones) => {
  const {
    handleEditar,
    handleEliminar,
    handleConsolidar,
    handlePDFProgramacionTintoreria,
    handlePDFConsolidadoTela,
  } = acciones;
  switch (e.key) {
    case "editar":
      handleEditar(record);
      break;
    case "eliminar":
      handleEliminar(record);
      break;
    case "consolidar":
      handleConsolidar(record);
      break;
    case "pdf_tintoreria":
      handlePDFProgramacionTintoreria(record);
      break;
    case "pdf_consolidado":
      handlePDFConsolidadoTela(record);
      break;
    default:
      break;
  }
};
const columns_table = (acciones) => {
  const {
    handleEditar,
    handleEliminar,
    handlePDFProgramacionTintoreria,
    handleConsolidar,
    handlePDFConsolidadoTela,
  } = acciones;
  return [
    {
      title: "#",
      dataIndex: "nro",
      key: "nro",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Fecha Emisión",
      dataIndex: "fechaemision",
      key: "fechaemision",
      align: "center",
    },
    {
      title: "Código",
      dataIndex: "codigo",
      key: "codigo",
      align: "center",
    },
    {
      title: "Proveedor",
      dataIndex: "proveedor_nombre",
      key: "proveedor_nombre",
      align: "center",
      render: (text, record) => (
        <>
          {text}
          <h5 style={{ margin: 0 }}>{record?.proveedor_ruc}</h5>
        </>
      ),
    },
    {
      title: "Rollos",
      dataIndex: "cantidadrollostotal",
      key: "cantidadrollostotal",
    },
    {
      title: "Peso bruto (KG)",
      dataIndex: "pesobrutototal",
      key: "pesobrutototal",
    },
    {
      title: "Peso neto (KG)",
      dataIndex: "pesonetototal",
      key: "pesonetototal",
    },
    {
      title: "Estado",
      dataIndex: "nombreestado",
      key: "nombreestado",
      render: (text, record) => (
        <EstadoProgramacionTag estado={record?.estado} estado_nombre={text} />
      ),
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      render: (text, record) => {
        let accionesFilter = [];
        if (record?.consolidadotela) {
          accionesFilter = itemsActions.filter((x) => x.key !== "eliminar");
        } else {
          accionesFilter = itemsActions;
        }
        return (
          <Space>
            <DropdownButtonUi
              items={accionesFilter}
              handleItemClick={(e) =>
                handleMenuClick(e, record, {
                  handleEditar,
                  handleEliminar,
                  handleConsolidar,
                })
              }
            />
            <DropdownButtonUi
              items={reportesActions}
              label="Reportes"
              handleItemClick={(e) =>
                handleMenuClick(e, record, {
                  handlePDFProgramacionTintoreria,
                  handlePDFConsolidadoTela,
                })
              }
            />
          </Space>
        );
      },
    },
  ];
};
export const ProgramacionTintoreriaTable = ({ data }) => {
  const dispatch = useDispatch();
  const handleEditar = (programaciontejido) => {
    dispatch(programacionTintoreriaSetActive(programaciontejido));
    dispatch(showFormProgramacionTintoreria());
  };
  const handleEliminar = (programacion) => {
    showDeleteConfirm(
      "¿Está seguro de eliminar la programación de tintoreria?",
      `${programacion.proveedor_nombre} - ${programacion.cantidadrollostotal} rollos`,
      (isConfirmed) => {
        if (isConfirmed) {
          dispatch(programacionTintoreriaStartDelete(programacion));
        }
      }
    );
  };
  const handleConsolidar = (row) => {
    dispatch(
      consolidadoTelaSetActive({
        ...row?.consolidadotela,
        proveedor_nombre: row?.proveedor_nombre,
        proveedor_ruc: row?.proveedor_ruc,
        codigo: row?.codigo,
        fechatintoreria: row?.fechaemision,
        programaciontintoreria: row?.id,
      })
    );
    dispatch(showFormConsolidadoTela());
  };
  const handlePDFProgramacionTintoreria = async (programacion) => {
    const file = await get_pdf_programacion_tintoreria(programacion?.id);
    let name_file = `${programacion.proveedor_ruc}-${programacion?.codigo}.pdf`;
    open_pdf_file(file, name_file);
  };
  const handlePDFConsolidadoTela = async (programacion) => {
    const file = await get_pdf_consolidado_tela(
      programacion?.consolidadotela?.id
    );
    let name_file = `Consolidado-${programacion?.codigo}.pdf`;
    open_pdf_file(file, name_file);
  };
  return (
    <TableUi
      columns={columns_table({
        handleEditar,
        handleEliminar,
        handleConsolidar,
        handlePDFProgramacionTintoreria,
        handlePDFConsolidadoTela,
      })}
      rowKey="id"
      dataSource={data}
      size="small"
    />
  );
};
