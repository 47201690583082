import { axiosConToken } from "../helpers/axios";
import { types } from "../types/typesComprobantePago";
import {
  messageErrorLoading,
  messageLoading,
  messageSuccessLoading,
  validaMessagesErrors,
} from "../helpers/messageConfig";
import { puntoventaSetResult } from "./venta";
import { toast } from "react-toastify";

const routes = {
  comprobantepago: "comprobantepago/",
};

export const comprobantespagoStartLoad = (
  tipofiltro = null,
  fechaemision = null,
  cliente = null,
  estadocomprobante = null
) => {
  return async (dispatch) => {
    try {
      const resp = await axiosConToken.get(routes.comprobantepago, {
        params: { tipofiltro, fechaemision, cliente, estadocomprobante },
      });
      const data = resp.data;
      dispatch(comprobantespagoLoaded(data));
    } catch (error) {
      validaMessagesErrors(error);
    }
  };
};
export const comprobantepagoStartAdd = (comprobantepago) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(
        routes.comprobantepago,
        comprobantepago
      );
      const body = resp.data;
      const { msg, comprobante } = body;
      dispatch(comprobantepagoAdded(comprobante));
      dispatch(comprobantepagoSetActive(comprobante));
      dispatch(puntoventaSetResult({ status: "success", msg }));
    } catch (error) {
      const errors = error.response;
      validaMessagesErrors(error, msgload);
      dispatch(
        puntoventaSetResult({ status: "error", msg: errors?.statusText })
      );
    } finally {
      toast.dismiss(msgload);
    }
  };
};
export const comprobantespagoNoEnviadosStartLoad = () => {
  return async (dispatch) => {
    try {
      const resp = await axiosConToken.get(
        `${routes.comprobantepago}get_comprobantes_no_enviados/`
      );
      const data = resp.data;
      dispatch(comprobantespagoLoaded(data));
    } catch (error) {
      validaMessagesErrors(error);
    }
  };
};
export const comprobantespagoSendSunat = (comprobante) => {
  const msgload = messageLoading("Guardando...");
  return async (dispatch) => {
    try {
      const resp = await axiosConToken.get(
        `${routes.comprobantepago}${comprobante?.id}/send_invoice_sunat/`
      );

      const response = resp.data;
      const { msg } = response;
      dispatch(comprobantepagoDeleted(comprobante));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      if (error?.response.status === 400) {
        dispatch(comprobantepagoDeleted(comprobante));
        messageErrorLoading(msgload, error?.response?.data?.msg);
      } else {
        validaMessagesErrors(error, msgload);
      }
    }
  };
};
export const comprobantespagoSendEmail = (comprobante, email_receiver) => {
  const msgload = messageLoading("Enviando...");
  return async () => {
    try {
      const resp = await axiosConToken.get(
        `${routes.comprobantepago}send_email_invoice/`,
        { params: { comprobante, email: email_receiver }, timeout: 6500 }
      );
      const response = resp.data;
      const { msg } = response;
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const comprobantepagoStartXMLCreate = (comprobanteId) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.get(
        `${routes.comprobantepago}create_xml_comprobantepago/`,
        { params: { comprobante: comprobanteId } }
      );
      const { msg, comprobante } = resp.data;
      dispatch(comprobantepagoUpdated(comprobante));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const NotasVentaStartLoad = (tipofiltro, fechaemision, cliente) => {
  return async (dispatch) => {
    try {
      const resp = await axiosConToken.get(
        `${routes.comprobantepago}get_notasventas/`,
        {
          params: { tipofiltro, fechaemision, cliente },
        }
      );
      const data = resp.data;
      dispatch(comprobantespagoLoaded(data));
    } catch (error) {
      validaMessagesErrors(error);
    }
  };
};
export const comprobantepagoByNotaVentaCreate = (
  notaventa_id,
  comprobante_data
) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(
        `${routes.comprobantepago}${notaventa_id}/generate_comprobanteelectronico_by_notaventa/`,
        comprobante_data
      );
      const { msg, comprobante, notaventa } = resp.data;
      dispatch(comprobantepagoUpdated(notaventa));
      dispatch(comprobantepagoSetActive(comprobante));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};

const comprobantespagoLoaded = (comprobantes) => ({
  type: types.comprobantesLoaded,
  payload: comprobantes,
});
const comprobantepagoAdded = (comprobante) => ({
  type: types.comprobantepagoAdded,
  payload: comprobante,
});
const comprobantepagoUpdated = (comprobante) => ({
  type: types.comprobantepagoUpdated,
  payload: comprobante,
});
const comprobantepagoDeleted = (comprobante) => ({
  type: types.comprobantepagoDeleted,
  payload: comprobante,
});
export const showModalComprobanteOptionPrint = (status = true) => ({
  type: types.comprobanteShowModalOptionPrint,
  payload: status,
});
export const comprobantepagoSetActive = (comprobante) => ({
  type: types.comprobantepagoSetActive,
  payload: comprobante,
});
export const comprobantepagoClearActive = () => ({
  type: types.comprobantepagoClearActive,
});
export const showModalItemDetalle = (visible = true) => ({
  type: types.cotizacionShowModalItemDetalle,
  payload: visible,
});
export const ShowModalComprobantePago = (status = true) => ({
  type: types.comprobantepagoShowModal,
  payload: status,
});
export const puntoventaSetCurrentStep = (current) => ({
  type: types.puntoventaSetCurrentStep,
  payload: current,
});

// Functions Async POS

export const get_tipomediospago = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get("comprobantepago/get_tipomediospago/");
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};

export const get_mediospago_by_tipomediopago = async (tipomediopago) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      "comprobantepago/get_mediospago_by_tipomediopago/",
      { params: { tipomediopago } }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};

export const get_productos_search = async (
  categoriaproducto = null,
  nombre = null,
  codigo = null,
  almacen = null
) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.comprobantepago}get_productos/`,
      {
        params: { categoriaproducto, nombre, codigo, almacen },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_inventarios_by_item = async (item) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.comprobantepago}get_inventarios_by_item/`,
      {
        params: { item },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_seriecomprobantes_by_terminalcajero_tipocomprobante = async (
  terminalcajero,
  tipocomprobante
) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.comprobantepago}get_seriescomprobante_by_terminal_tipocomprobante/`,
      { params: { terminalcajero, tipocomprobante } }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_clientes_by_tipocomprobante = async (tipocomprobante) => {
  try {
    const resp = await axiosConToken.get(
      `${routes.comprobantepago}get_clientes_by_tipocomprobante/`,
      { params: { tipocomprobante } }
    );
    return resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
};
export const get_tipocomprobantepagos_puntoventa_by_terminalcajero = async (
  terminalcajero
) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.comprobantepago}get_tipocomprobantespagos_puntoventa_by_terminalcajero/`,
      {
        params: { terminalcajero },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_monedas = async (terminalcajero) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.comprobantepago}get_monedas/`,
      {
        params: { terminalcajero },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_tipocomprobantepagos_by_terminalcajero = async (
  terminalcajero
) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.comprobantepago}get_tipocomprobantespagos_by_terminalcajero/`,
      {
        params: { terminalcajero },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_empleados_vendedores = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.comprobantepago}get_vendedores_select/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_tiposdescuentos = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.comprobantepago}get_tiposdescuentos/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};

export const get_categorias = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.comprobantepago}get_categorias/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_unidadesmedida = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.comprobantepago}get_unidadesmedida/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_formaspago = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.comprobantepago}get_formaspago_select/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const file_download_xml_comprobantepago = async (comprobante) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.comprobantepago}get_xml_comprobantepago/`,
      {
        responseType: "blob",
        params: { comprobante },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const file_download_cdr_comprobantepago = async (comprobante) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.comprobantepago}get_cdr_comprobantepago/`,
      {
        responseType: "blob",
        params: { comprobante },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_pdf_boleta_venta = async (comprobante, size_type) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.comprobantepago}get_pdf_boleta_venta/`,
      {
        responseType: "blob",
        params: { comprobante, size_type },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_pdf_factura_venta = async (comprobante, size_type) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.comprobantepago}get_pdf_factura_venta/`,
      {
        responseType: "blob",
        params: { comprobante, size_type },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
export const get_pdf_nota_venta = async (comprobante, size_type) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.comprobantepago}get_pdf_nota_venta/`,
      {
        responseType: "blob",
        params: { comprobante, size_type },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error.response);
  }
  return datos;
};
